<template>
	<div>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;padding: 10px 10px 0 10px;">
			<div style="">
				
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;padding-top: 20px;">
					<el-input style="width:270px;" type="text" v-model="form.mobile" auto-complete="off" :disabled="true">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>
				
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<div>新密码</div>
					<a style="color: red;">*</a>
				</div>
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<el-input style="width:270px;" type="password" v-model="form.pword" auto-complete="off">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>
			</div>
			
			<div style="">
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<div>确认新密码</div>
					<a style="color: red;">*</a>
				</div>
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<el-input style="width:270px;" type="password" v-model="form.confirmpwd" auto-complete="off">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>
			</div>
			
			<!-- 验证码 -->
			<div style="display: flex;width: 100%;align-items: center;justify-content: center;">
				<el-input style="width:150px;margin-right: 10px;" v-model="form.smscode" type="text" auto-complete="off"
					:placeholder="$t('register.right.smscode')">
					<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
				</el-input>
				<el-button v-bind:class="{grey:isGrey,blue:!isGrey}" v-bind:disabled="dis" type="primary"
					@click="getCode">
					<span v-if="show">{{$t('register.right.smscodeButton1')}}</span>
					<span v-else>{{$t('register.right.smscodeButton2')}}({{count}}s)</span>
				</el-button>
			</div>
			
			<div class="bottom">
				<c-button type="warning" :height="33" style="margin-left: 10px;" @click="changePassword">保存</c-button>
			</div>
		</div>
		
		<applet-bottom></applet-bottom>
	</div>
</template>

<script>
	import {
		getUser,
		updateAdd,
		updatepword
	} from '@/api/userInfo/index'
	import appletBottom from '@/components/bottom/appletIndex.vue';
	import {
		getCode,
	} from '@/api/home/index.js'
	import Cookies from "js-cookie";
	import CButton from '@/components/CButton/index.vue'//button组件引入
	export default {
		components: {
			CButton,
			appletBottom
		},
		data() {
			return {
				userInfo: null, //获取用户信息接口
				// 表单参数
				form: {
					id:undefined,//用户编号
					qq:undefined,//qq号码
					email:undefined,//邮箱
					uid:undefined,//用户编号
					pword:undefined,//新密码
					confirmpwd:undefined,//确认密码
					smscode:undefined,//验证码
					mobile:undefined,//手机号
				},
				
				dis: false,
				show: true,
				isGrey: true, //按钮样式
				timer: null, //设置计时器,
				count: "",
			}
		},

		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			if(this.userInfo){
				this.form.mobile = this.userInfo.phonenumber
				this.form.uid = this.userInfo.uid
			}
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//-------------------验证码计时----------------------------------
			getCode: function() {
				let data = {
					mobile: this.form.mobile
				}
				getCode(data).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isGrey = true;
							this.show = false;
							this.dis = true;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.dis = false;
									this.isGrey = true;
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			//-----------------------修改密码------------------------
			changePassword:function(){
				if(this.form.pword==undefined){
					this.$message.error('请输入新密码')
				}else if(this.form.confirmpwd==undefined){
					this.$message.error('请输入再次输入新密码')
				}else if(this.form.pword!==this.form.confirmpwd){
					this.$message.error('请再次输入的密码和新密码一致')
				}else if(this.form.smscode==undefined){
					this.$message.error('请输入验证码')
				}else{
					// console.log("form",this.form)
					updatepword(this.form).then(res=>{
						this.form.pword=undefined
						this.form.confirmpwd=undefined
						this.$message.success('修改密码成功')
						this.$router.push({
							path: '/personal',
						})
					})
				}
			},
		}
	}
</script>

<style lang="less">
	/deep/ .ant-tabs-ink-bar {
		background-color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-tab:hover {
		color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-bar {
		margin: 0 !important;
	}
	
	/deep/ .ant-tabs-tab-active {
		color: #f2ab15 !important;
	}
	
	.user {
		display: flex;
		padding: 10px 0;
	}
	
	.bottom{
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>